
.status-pic {
    max-width: 15px;
    float: right;
}


.buffer-order-tracking{
    padding-top: 0px;
    padding-bottom: 30px;
}

.updateStatus-modal-body
{    
    height: 180px;
    overflow-y: scroll;
}

.confirmInvoice-modal-body
{
    height:232px;
    overflow-y: scroll;
}