.order-delayed{
    color: #d00000;
    font-weight: bold;
}

.order-warning{
    color: #f3c408;
    font-weight: bold;
}

.no-img-space{
    float:left !important;
    font-size: 0 !important;
    cursor:pointer;
}

.column-left{
    align-items: center;
    display: flex;
    height: 33px;
    justify-content: left;
}

.column-center{
    align-items: center;
    display: flex;
    height: 33px;
    justify-content: center;
}

.column-right{
    align-items: center;
    display: flex;
    height: 33px;
    justify-content: flex-end;
}

.mobile-grid-font{
    font-size: 25;
}

.text-wrap{
    word-wrap: break-word;
    line-height: 13pt;
}

.row-expanded{
    float: 'left';
    background-color: #FAFAFA;
    padding-bottom: 4px;
}

.mobile-grid-labels{
    font-size: 12px;
    font-weight: bold;
}

.mobile-grid-expanded-text{
    font-size: 12px;
}

.mobile-grid-header{
    font-weight: bold;
}

.grid-header{
    font-weight: bold;
}

.grid-row-center{
    text-align: center;
}

.grid-row-right{
    text-align: right;
}

.grid-row-left{
    text-align: left;
}

.grid-header-no-padding{
    padding:0px
}

.grid-header-check-cell{
    line-height:15px;
    /* align-items: center;
    display: flex;
    height: 33px;
    justify-content: center; */
}

.check-center{
    line-height: 15px;
}