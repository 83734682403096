
.buffer-order-item{
    padding-top: 15px;
}

.grid-header{
    font-weight: bold;
}

.grid-row-left{
    text-align: left;
}

.grid-row-center{
    text-align: center;
}

.grid-row-right{
    text-align: right;
}

.rs-divider-vertical{
    height: auto !important;
    border: solid #e5e5ea 1px;
}

.mt-2 {
    margin: none;
}

.-m-2.mt-2.-ml-1.rs-checkbox {
    margin-top: 0px;
    bottom: 8px;
}
