.parent {
  background-color: #f5f9fc;
}

.child {
  background-color: white;
}

.sla-percentage {
  font-size: 250%;
  font-weight: bold;
  color: #586d81;
}

.ontime-orders {
  font-size: 250%;
  font-weight: bold;
  color: #008000;
}

.offtime-orders {
  font-size: 250%;
  font-weight: bold;
  color: #ff0000;
}

.kpi-description {
  font-size: 100%;
  white-space: pre-line;
}

.last-update {
  font-size: 12px;
  white-space: pre-line;
}

.units-description-gray {
  font-size: 40%;
  color: #586d81;
}

.units-description-red {
  font-size: 40%;
  color: red;
}

.units-description-green {
  font-size: 40%;
  color: green;
}
.kpi-container {
  display: flex;
}

p {
  line-height: 0.9;
}

h3 {
  line-height: 1.2;
}

.header-links {
  color: gray;
}

.shadow-div {
  /* box-shadow: 0 0 0px 0px gray; */
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.chart-container {
  position: relative;
  margin: auto;
  height: 40vh;
  width: 40vw;
}

/* .margin-chart{
    left-margin:-15px;
} */

/* canvas {
    width:100%;
    height: auto;
    margin:0;
    padding:0;
  } */
