.no-spaces-left {
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.select-menu {
  z-index: 1000 !important;
}

.whisper-width {
  width: 16px;
  height: 16px;
}

/* .paragraph-message {
  white-space: pre-line;
} */

.paragraph-message {
  white-space: pre-line;
}

.modal-title-center {
  text-align: center;
}

.no-caps-button-text {
  text-transform: none;
}

.navbar-brand {
  padding: 15px 20px;
  display: inline-block;
  font-weight: bold;
}
