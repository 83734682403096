@import "./styles/normalizer.css";
@tailwind components;
@tailwind utilities;
@import "rsuite/dist/rsuite.min.css";
@import "./styles/rsuite-overrides.css";

@layer components {
  .fix-div-totals{
    @apply p-2.5 fixed  right-6 left-6 rounded-md	bg-white border-b-2;
    top: 75px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-neutral-50;
}

#root {
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

.rs-panel {
  @apply bg-white;
}

.rs-panel-body, .rs-panel-header {
  padding: 20px 20px 10px 20px;
}

.rs-btn-icon-circle {
  overflow: unset;
}